import React, { memo } from 'react';

import styled from 'styled-components';

import { ColorService, desktop, getFontFamily, tablet } from 'services';
import { Counter, Icon, IconsNames } from 'components/atoms';
import { ContactForm } from 'components/molecules';
import {
  Box,
  ErrorText,
  Form,
  FormText,
  Hint,
  PhoneInputWrapper,
  StyledButton,
  StyledErrorForm,
  StyledInput,
  StyledInputMask,
} from 'components/molecules/ContactForm/ContactForm';

interface IMainFormProps {
  hasDiscountInfo?: boolean;
  isDayOffer?: boolean;
  discountPrice?: string;
  oldPrice?: string;
  perMonth?: string;
  className?: string;
  benefitIcon?: IconsNames;
}

export const MainForm = memo(
  ({
    hasDiscountInfo,
    discountPrice,
    oldPrice,
    perMonth,
    className,
    benefitIcon,
  }: IMainFormProps) => (
    <FormWrapper className={className}>
      {hasDiscountInfo && (
        <>
          <DiscountInfoWrapper>
            <ForCourseText>Стоимость курса</ForCourseText>
            <Prices>
              <DiscountPrice>{discountPrice}</DiscountPrice>
              <OldPrice>{oldPrice}</OldPrice>
            </Prices>
            <MonthPrice>
              {perMonth} <PerMonth>BYN/мес.</PerMonth>
            </MonthPrice>
            <ForMonthText>Рассрочка на 18 месяцев</ForMonthText>
          </DiscountInfoWrapper>

          {benefitIcon && (
            <StyledIcon name={benefitIcon} width={150} height={150} />
          )}
        </>
      )}
      <CounterStyled />

      <ContactForm
        titleBtn="Записаться"
        text="Мы свяжемся в течение рабочего дня, бесплатно проконсультируем и поможем с выбором направления. Наша цель - твоя новая карьера в IT!"
      />
    </FormWrapper>
  ),
);

const FormWrapper = styled.form`
  position: relative;
  background-color: ${ColorService.BG_GRAY};
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 40px 32px;
  border: 1px solid ${ColorService.SILVER};
  margin: 64px -16px 0;

  ${tablet} {
    margin: 80px 0 0;
    padding: 40px;
  }

  ${desktop} {
    width: 424px;
    margin: 0;
  }

  ${Box} {
    padding: 0;
  }

  ${Form} {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  ${Hint} {
    display: none;
  }

  ${FormText} {
    display: block;
    text-align: center;
  }

  ${PhoneInputWrapper} {
    margin-bottom: 24px;
  }

  ${StyledInput} {
    width: 100%;
  }

  ${StyledInputMask} {
    width: 100%;
  }

  ${StyledButton} {
    width: 100%;
    margin-bottom: 24px;
  }

  ${ErrorText} {
    max-width: 360px;
  }

  ${StyledErrorForm} {
    margin-top: -22px;
    text-align: center;
  }
`;

const CounterStyled = styled(Counter)`
  width: 100%;
  height: 86px;
  margin-bottom: 24px;
`;

const DiscountInfoWrapper = styled.div`
  display: grid;
  grid-row-gap: 4px;
  grid-template-areas:
    'forCourse'
    'price'
    'monthPrice'
    'forMonth';
  margin-bottom: 24px;
  margin-right: 32px;

  ${tablet} {
    grid-template-areas:
      'monthPrice price'
      'forMonth forCourse';
    align-items: baseline;
    margin-right: 130px;
  }

  ${desktop} {
    grid-template-areas:
      'forCourse'
      'price'
      'monthPrice'
      'forMonth';
    margin-right: 84px;
  }
`;

const Text = styled.p`
  color: ${ColorService.GRAY};
`;

const ForCourseText = styled(Text)`
  grid-area: forCourse;
`;

const ForMonthText = styled(Text)`
  grid-area: forMonth;
`;

const Prices = styled.div`
  grid-area: price;
  display: flex;
  gap: 16px;
  color: ${ColorService.WHITE};
`;

const DiscountPrice = styled.p`
  font-family: ${getFontFamily('medium')};
  font-size: 24px;
  line-height: 36px;
`;

const OldPrice = styled.p`
  font-family: ${getFontFamily('medium')};
  font-size: 18px;
  line-height: 36px;
  text-decoration-line: line-through;
`;

const MonthPrice = styled.p`
  grid-area: monthPrice;
  font-family: ${getFontFamily('bold')};
  font-size: 42px;
  line-height: 54px;
  color: ${ColorService.YELLOW};
`;

const PerMonth = styled.span`
  font-family: ${getFontFamily('bold')};
  font-size: 32px;
  line-height: 54px;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: -46px;
  right: -10px;
  z-index: 2;
`;
